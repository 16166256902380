// import { UI } from 'views/UI'

// const Index = () => {
//   return <UI />
// }

// export default Index


import Swap from '../views/Swap'

const SwapPage = () => {
  return <Swap />
}

export default SwapPage
